import { useLocale, useTranslations } from 'next-intl'

const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24
const ONE_WEEK = ONE_DAY * 7
const ONE_MONTH = ONE_WEEK * 4
const ONE_YEAR = ONE_MONTH * 12

const useTimePass = (past: number, showPassWeeks: boolean = true) => {
  const t = useTranslations()
  const language = useLocale()

  if (!past) return ''

  const now = new Date().getTime()
  const time = new Date(past).getTime()
  const currentYear = new Date(now).getFullYear()
  const pastYear = new Date(past).getFullYear()
  const timeDiff = now - time
  let result = ''
  if (timeDiff < ONE_MINUTE) {
    result = `${Math.floor(timeDiff / ONE_SECOND)}${t('common.second_abbr')}`
  } else if (timeDiff < ONE_HOUR) {
    result = `${Math.floor(timeDiff / ONE_MINUTE)}${t('common.minute_abbr')}`
  } else if (timeDiff < ONE_DAY) {
    result = `${Math.floor(timeDiff / ONE_HOUR)}${t('common.hour_abbr')}`
  } else if (timeDiff < ONE_WEEK) {
    result = `${Math.floor(timeDiff / ONE_DAY)}${t('common.day_abbr')}`
  } else if (showPassWeeks && timeDiff < ONE_MONTH) {
    result = `${Math.floor(timeDiff / ONE_WEEK)}${t('common.week_abbr')}`
  } else if (timeDiff < ONE_YEAR) {
    result = `${Math.floor(timeDiff / ONE_MONTH)}${t('common.month_abbr')}`
  } else {
    result = `${Math.floor(timeDiff / ONE_YEAR)}${t('common.year_abbr')}`
  }

  const localeOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric'
  }

  if (currentYear !== pastYear) {
    localeOptions.year = 'numeric'
  }

  return (
    result ||
    new Date(past).toLocaleString(
      language.includes('en') ? 'en-US' : language,
      localeOptions
    )
  )
}

export default useTimePass
