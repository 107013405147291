import { isVideoAsset, type IAssetV2 } from 'type/asset'
import type { Nullable } from 'type/common'
import { MediaType, isCommentMedia, type IMediaV2 } from 'type/media'
import { cn, twc } from 'util/cn'
import { showMedia } from 'util/comment'
import MultiMedia from './multi-media'
import UserText from './user-text'
import { Separator } from 'component/ui/separator'

const Bubble = twc.div`isolate rounded-[12px] bg-background-2nd overflow-hidden`

const Content = ({
  comment,
  user,
  commentTime,
  media,
  interactiveTools,
  showMoreLabel = false
}: {
  comment?: Nullable<string>
  user?: Nullable<{
    user_id: string
    uid: string
    name: string
    headshot?: Nullable<IAssetV2>
  }>
  commentTime: number
  media?: Nullable<IMediaV2[]>
  interactiveTools: React.ReactNode
  showMoreLabel?: boolean
}) => {
  let _media = media?.[0]

  const showFirst = showMedia(media, 'first')
  const showSecond = showMedia(media, 'second')

  return (
    <div className={cn('min-w-0 ml-[8px]', showSecond && 'min560:w-[325px]')}>
      <Bubble
        className={cn(
          'mb-[4px]',
          isCommentMedia(_media) && !_media.comment.media && 'w-fit',
          !showFirst && 'w-fit',
          emptyMedia(_media) && 'w-fit'
        )}
      >
        <UserText
          comment={comment}
          name={user?.name}
          uid={user?.uid}
          commentTime={commentTime}
          showMoreLabel={showMoreLabel}
        />
        {showFirst && (
          <>
            {_media!.type === MediaType.Comment && (
              <Separator orientation='horizontal' />
            )}
            <MultiMedia media={_media!} />
          </>
        )}
      </Bubble>
      {showSecond && (
        <Bubble className='mb-[4px]'>
          <MultiMedia media={_media!} />
        </Bubble>
      )}
      {interactiveTools}
    </div>
  )
}

export default Content

const emptyMedia = (media: Nullable<IMediaV2>) => {
  if (!media) return true

  switch (media.type) {
    case MediaType.Link: {
      const asset = media.asset
      if (!asset || !asset.weblink_metadata) return true
      break
    }
    case MediaType.Image: {
      const asset = media.asset
      if (!asset || !asset.image_metadata) return true
      break
    }
    case MediaType.Video: {
      const asset = media.asset
      if (!asset || !asset.video_metadata) return true
      break
    }
    case MediaType.Spot: {
      const asset = media?.spot?.asset || media?.asset
      if (!asset) return true
      if (!isVideoAsset(asset)) return true
      break
    }
  }

  return false
}
