'use client'

import React from 'react'

import { Image } from 'component/ui/image'
import type { IWeblinkAssetV2 } from 'type/asset'
import { twc } from 'util/cn'
import validateUrl from 'util/validateUrl'

const ImageLinkText = twc.span`flex-shrink-1 min-w-0 truncate`
const ImageLinkFooter = twc.div`absolute bottom-0 left-0 right-0 p-[12px] bg-gradient-to-b from-[rgba(0,5,5,0)] to-[--overlay-thick]`
const ImageLinkWithIcon = twc.p`text-md text-label_still-l1 flex items-center min-w-0 w-full`

const MediaLink = (props: { asset: IWeblinkAssetV2 }) => {
  const { asset } = props

  const [imgBroken, setImgBroken] = React.useState(false)

  const {
    weblink_metadata: {
      display_url: displayUrl,
      title: urlTitle,
      thumbnail: thumbnail
    }
  } = asset

  const url = validateUrl(displayUrl)
  const displayText = urlTitle || displayUrl

  const linkProps = {
    href: url,
    target: '_blank',
    rel: 'noopener noreferrer nofollow'
  } as const

  if (thumbnail && !imgBroken) {
    let src = ''
    if (thumbnail.pixel_720?.url) {
      src = thumbnail.pixel_720.url
    } else if (thumbnail.pixel_200?.url) {
      src = thumbnail.pixel_200.url
    } else if (thumbnail.pixel_196?.url) {
      src = thumbnail.pixel_196.url
    } else {
      src = thumbnail.pixel_64?.url || ''
    }

    if (!src) {
      setImgBroken(true)
      return
    }

    return (
      <a
        {...linkProps}
        className='relative block w-full pt-[calc(9/16_*_100%)]'
        onClick={e => e.stopPropagation()}
      >
        <Image
          src={src}
          fill={true}
          loading='lazy'
          alt="Link's cover"
          onError={() => setImgBroken(true)}
        />
        <ImageLinkFooter>
          <ImageLinkWithIcon>
            <span
              className='[--icon-size:18px] i-ps-link mr-[4px] shrink-0'
              role='img'
              aria-label='Link'
            />
            <ImageLinkText>{displayText}</ImageLinkText>
          </ImageLinkWithIcon>
        </ImageLinkFooter>
      </a>
    )
  }

  return (
    <a
      {...linkProps}
      className='flex items-center mb-[12px] mx-[12px] p-[12px] rounded-[12px] bg-background-1st text-md text-label-l1'
      onClick={e => e.stopPropagation()}
    >
      <div
        className='[--icon-size:18px] i-ps-link mr-[4px] shrink-0'
        role='img'
        aria-label='Link'
      />
      <ImageLinkText>{displayText}</ImageLinkText>
    </a>
  )
}

export default MediaLink
