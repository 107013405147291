'use client'

import * as React from 'react'

import VideoPlayer from 'component/block/videoPlayer'
import useLazyRef from 'hook/useLazyRef'
import { createSpotControl } from 'lib/control/spot'
import { idleTimerStore } from 'store/global/idle-timer'
import {
  mutedStore,
  playStateStore,
  useIsMuted,
  useIsUserPaused,
  userPausedStore
} from 'store/global/video'
import cn, { twc } from 'util/cn'

const PlayContainer = twc.div`absolute bottom-0 left-0 right-0 top-0 z-[2] flex cursor-pointer select-none items-center justify-center drop-shadow-image`

const VoiceButton = twc.div`absolute bottom-0 right-0 m-[8px] p-[8px] cursor-pointer z-[3]`

const MediaVideo = ({
  src,
  width,
  height,
  thumbnail
}: {
  src: string
  width: number
  height: number
  thumbnail: string
}) => {
  const isUserPaused = useIsUserPaused()
  const spotControl = useLazyRef(() =>
    createSpotControl({
      idleTimerStore,
      userPausedStore,
      mutedStore,
      playStateStore
    })
  )

  const videoCallbackRef = React.useCallback(
    (el: HTMLVideoElement | null) => {
      if (el) {
        spotControl.current.attach(el)
      } else {
        spotControl.current.detach()
      }
    },
    [spotControl]
  )

  const containerCallbackRef = React.useRef<HTMLDivElement | null>(null)
  const isMuted = useIsMuted()

  React.useEffect(() => {
    const container = containerCallbackRef.current
    if (!container) return

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            spotControl.current.onScreen()
          } else {
            spotControl.current.offScreen()
          }
        })
      },
      {
        rootMargin: '0px 0px 0px 0px',
        threshold: 0.5
      }
    )

    observer.observe(container)

    return () => {
      observer.disconnect()
    }
  }, [spotControl])

  return (
    <div
      ref={containerCallbackRef}
      onClick={event => {
        event.stopPropagation()
        event.preventDefault()
      }}
      className='bg-background_still-1st relative'
    >
      <div className='w-full'>
        <VideoPlayer
          playerRef={videoCallbackRef}
          className='block h-full w-full max-h-[500px] min-w-[auto] object-contain'
          src={src}
          muted
          width={width}
          height={height}
          preload='metadata'
          playsInline
          webkit-playsinline='true'
          loading='lazy'
          autoPlay={false}
          onEnded={spotControl.current.play}
          onPlaying={idleTimerStore.stopWatch}
          errorVideoPoster={decodeURIComponent(thumbnail)}
        />
      </div>
      <PlayContainer
        onClick={userPausedStore.toggle}
        role='button'
        aria-label='Play'
      >
        <div
          className='text-background-1st i-ps-play_f [--icon-size:48px] aria-[hidden="true"]:hidden'
          role='img'
          aria-label='Play'
          aria-hidden={!isUserPaused}
        />
      </PlayContainer>
      <VoiceButton
        onClick={mutedStore.toggle}
        role='button'
        aria-label='Mute and Unmute'
      >
        <div
          className='bg-glass_ios-20blur_still rounded-full sq-[24px] flex'
          role='img'
          aria-label='Mute and Unmute'
        >
          <div
            className={cn(
              '[--icon-size:16px] text-background-1st m-auto',
              isMuted ? 'i-ps-volume_off_f' : 'i-ps-volume_on_f'
            )}
          />
        </div>
      </VoiceButton>
    </div>
  )
}

export default MediaVideo
