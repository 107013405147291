import React from 'react'
import HeadShot from 'component/block/headshot'
import { HeadshotSize } from 'constant'
import type { IAssetV2 } from 'type/asset'
import type { Nullable } from 'type/common'
import { cn } from 'util/cn'
import { normalizeAsset } from 'util/normalizeAsset'
import LinkComment from './link-comment'

interface IComment {
  debugType?: any
  className?: string
  connectTop?: boolean
  connectBottom?: boolean
  commentId?: string
  communityId?: string
  user?: Nullable<{
    user_id: string
    uid: string
    headshot?: Nullable<IAssetV2>
  }>

  title?: Nullable<React.ReactNode>
  children: React.ReactNode
  asLink?: boolean
}
const Comment = (props: IComment) => {
  const {
    debugType,
    className,
    connectTop,
    connectBottom,
    user,
    title,
    children,
    asLink,
    communityId,
    commentId
  } = props

  if (!user) return null

  const isLink = !!(asLink && commentId)

  return (
    <LinkComment
      className={cn('px-[16px] w-full block', className)}
      debugType={debugType}
      isLink={isLink}
      link={
        isLink
          ? `${communityId ? `/community/${communityId}` : ''}/comment/${commentId}`
          : undefined
      }
    >
      {connectTop && (
        <div className='w-[36px] h-[20px] shrink-0 flex flex-col'>
          <div className='mx-auto w-[1px] flex-grow mb-[2px] bg-label-l3' />
        </div>
      )}
      {title}
      <div className='grid grid-cols-[36px,1fr]'>
        {/* Left avatar */}
        <div className='relative flex flex-col items-center'>
          <LinkComment link={`/${user.uid}`} className='shrink-0'>
            <HeadShot
              size={HeadshotSize.sm}
              {...normalizeAsset(user.headshot)}
            />
          </LinkComment>

          {connectBottom && (
            <div className='mx-auto w-[1px] mt-[2px] flex-grow bg-label-l3' />
          )}
        </div>
        {/* Right content */}
        {children}
      </div>
    </LinkComment>
  )
}

export default Comment
