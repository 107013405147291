'use client'

import { HeadshotSize } from 'constant'
import type { IAssetV2 } from 'type/asset'
import type { Nullable } from 'type/common'
import { cn } from 'util/cn'
import { normalizeAsset } from 'util/normalizeAsset'
import Headshot from '../headshot'
import Link from '../link'
import TimePass from './time-pass'
import { MoreText } from 'component/ui/more-text'

const UserText = ({
  comment,
  headshot,
  name,
  uid,
  commentTime,
  className,
  type = 'comment',
  showMoreLabel
}: {
  comment?: Nullable<string>
  name?: string
  uid?: string
  headshot?: Nullable<IAssetV2>
  commentTime: number
  className?: string

  // 應用在 comment page 上
  type?: 'page' | 'comment'
  showMoreLabel?: boolean
}) => {
  return (
    <div
      className={cn('px-[12px] pt-[8px] pb-[8px] group w-full', className)}
      data-type={type}
    >
      <div className='flex items-center'>
        <Link
          href={`/${uid}`}
          title={uid}
          className='flex items-center min-w-0'
          onClick={e => e.stopPropagation()}
        >
          {headshot && (
            <Headshot
              size={type === 'page' ? HeadshotSize.sm : HeadshotSize.xxs}
              {...normalizeAsset(headshot)}
              className='shrink-0 group-data-[type="page"]:mr-[8px] group-data-[type="comment"]:mr-[4px]'
            />
          )}
          <span className='truncate text-md font-semibold text-label-l1'>
            {name}
          </span>
          <div
            className='whitespace-nowrap text-sm text-label-l2'
            suppressHydrationWarning
          >
            {uid && <span className='px-[4px]'>@{uid}</span>}

            <span className='px-[4px]'>·</span>
            <TimePass timestamp={commentTime} />
          </div>
        </Link>
      </div>
      {comment && (
        <MoreText lineClamp={15} text={comment} showMoreLabel={showMoreLabel} />
      )}
    </div>
  )
}

export default UserText
