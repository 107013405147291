import type {
  IAssetV2,
  IImageAssetV2,
  IVideoAssetV2,
  IWeblinkAssetV2
} from './asset'
import type { ICommentV2 } from './comment'
import type { Nullable } from './common'
import type { ICommunityV2 } from './community'
import type { ILocationV2, IPlaceV2 } from './geo'
import type { IHashtagV2 } from './hashtag'
import type { ISpotV2 } from './spot'
import type { IUserV2WithAsset } from './user'

export const enum MediaType {
  Image = 1,
  Video = 2,
  Link = 3,
  Spot = 4,
  Community = 5,
  Comment = 6,
  Profile = 7,
  Hashtag = 8,
  NewSpot = 10
}

export interface IMediaBase {
  type: MediaType
  asset_id: string
  asset?: Nullable<IAssetV2>
}

interface IMediaAssetImage extends IMediaBase {
  type: MediaType.Image
  asset_id: string
  asset?: Nullable<IImageAssetV2>
}

interface IMediaAssetVideo extends IMediaBase {
  type: MediaType.Video
  asset_id: string
  asset?: Nullable<IVideoAssetV2>
}

interface IMediaAssetLink extends IMediaBase {
  type: MediaType.Link
  asset_id: string
  asset?: Nullable<IWeblinkAssetV2>
}

export type IMediaAsset = IMediaAssetImage | IMediaAssetVideo | IMediaAssetLink
export interface IMediaSpot extends IMediaBase {
  type: MediaType.Spot
  spot: ISpotV2 & {
    asset?: Nullable<IAssetV2>
    place?: Nullable<IPlaceV2>
  }
  user?: Nullable<IUserV2WithAsset>
}

export interface IMediaNewSpot extends IMediaBase {
  type: MediaType.NewSpot
  spot: ISpotV2 & {
    asset?: Nullable<IAssetV2>
    place?: Nullable<IPlaceV2>
  }
}

export interface IMediaCommunity extends IMediaBase {
  type: MediaType.Community
  community: ICommunityV2 & {
    asset?: Nullable<IAssetV2>
    location?: Nullable<ILocationV2>
  }
}

export interface IMediaComment extends IMediaBase {
  type: MediaType.Comment
  comment: ICommentV2 & {
    user?: Nullable<IUserV2WithAsset>
  }
}

export interface IMediaProfile extends IMediaBase {
  type: MediaType.Profile
  profile_id: string
  user?: Nullable<IUserV2WithAsset>
}

export interface IMediaHashtag extends IMediaBase {
  type: MediaType.Hashtag
  hashtag: IHashtagV2 & {
    asset?: Nullable<IAssetV2>
  }
}

export type IMediaV2 =
  | IMediaAsset
  | IMediaSpot
  | IMediaCommunity
  | IMediaComment
  | IMediaProfile
  | IMediaHashtag
  | IMediaNewSpot

type AssertMediaType = IMediaV2 | undefined | null

export function isAssetMedia(media: AssertMediaType): media is IMediaAsset {
  if (!media) return false
  return [MediaType.Image, MediaType.Video, MediaType.Link].includes(media.type)
}

export function isSpotMedia(media: AssertMediaType): media is IMediaSpot {
  if (!media) return false
  return media.type === MediaType.Spot
}

export function isNewSpotMedia(media: AssertMediaType): media is IMediaNewSpot {
  if (!media) return false
  return media.type === MediaType.NewSpot
}

export function isCommunityMedia(
  media: AssertMediaType
): media is IMediaCommunity {
  if (!media) return false
  return media.type === MediaType.Community
}

export function isCommentMedia(media: AssertMediaType): media is IMediaComment {
  if (!media) return false
  return media.type === MediaType.Comment
}

export function isProfileMedia(media: AssertMediaType): media is IMediaProfile {
  if (!media) return false
  return media.type === MediaType.Profile
}

export function isHashtagMedia(media: AssertMediaType): media is IMediaHashtag {
  if (!media) return false
  return media.type === MediaType.Hashtag
}

export type IMediaInBubble = IMediaComment | IMediaSpot | IMediaAsset
export function isInBubbleMedia(
  media: AssertMediaType
): media is IMediaInBubble {
  if (!media) return false
  return [
    MediaType.Image,
    MediaType.Video,
    MediaType.Link,
    MediaType.Spot,
    MediaType.Comment
  ].includes(media.type)
}
