import { isVideoAsset } from 'type/asset'
import { MediaType, type IMediaV2 } from 'type/media'
import MediaImage from './media-image'
import MediaLink from './media-link'
import MediaShare from './media-share'
import MediaVideo from './media-video'
import UserText from './user-text'
import Link from '../link'

const MultiMedia = ({ media }: { media: IMediaV2 }) => {
  if (!media) return null

  switch (media.type) {
    case MediaType.Link: {
      const asset = media.asset
      if (!asset) return null
      return <MediaLink asset={asset} />
    }
    case MediaType.Image: {
      const asset = media.asset
      if (!asset || !asset.image_metadata) return null
      return <MediaImage asset={asset} />
    }
    case MediaType.Video: {
      const asset = media.asset
      if (!asset) return null
      return (
        <MediaVideo
          src={asset.video_metadata.display_url}
          height={asset.video_metadata.height}
          width={asset.video_metadata.width}
          thumbnail={asset.video_metadata.thumbnail.pixel_720.url}
        />
      )
    }
    case MediaType.Spot: {
      const asset = media?.spot?.asset || media.asset
      if (!asset) return null
      if (!isVideoAsset(asset)) return null

      return (
        <>
          <UserText
            comment={media.spot.description}
            commentTime={media.spot.created_at}
            headshot={media.user?.headshot}
            name={media.user?.name}
            uid={media.user?.uid}
          />
          {media.spot.place?.name && (
            <Link href={`/place/${media.spot.place_id}?tab=1`}>
              <div className='flex items-center px-[12px] pb-[8px]'>
                <div
                  className='i-ps-pin_s mr-[4px] shrink-0 [--icon-size:16px]'
                  role='img'
                  aria-label='Location'
                />

                <span className='truncate'>{media.spot.place?.name}</span>
              </div>
            </Link>
          )}
          <MediaVideo
            src={asset.video_metadata.display_url}
            height={asset.video_metadata.height}
            width={asset.video_metadata.width}
            thumbnail={asset.video_metadata.thumbnail.pixel_720.url}
          />
        </>
      )
    }
    case MediaType.NewSpot: {
      const asset = media?.spot?.asset || media.asset
      if (!asset) return null
      if (!isVideoAsset(asset)) return null

      return (
        <>
          {media.spot.place?.name && (
            <Link href={`/place/${media.spot.place_id}?tab=1`}>
              <div className='flex items-center pr-[12px] pb-[8px]'>
                <div
                  className='i-ps-pin_s mr-[4px] shrink-0 [--icon-size:16px]'
                  role='img'
                  aria-label='Location'
                />

                <span className='truncate'>{media.spot.place?.name}</span>
              </div>
            </Link>
          )}
          <MediaVideo
            src={asset.video_metadata.display_url}
            height={asset.video_metadata.height}
            width={asset.video_metadata.width}
            thumbnail={asset.video_metadata.thumbnail.pixel_720.url}
          />
        </>
      )
    }
    case MediaType.Comment: {
      const link = `/community/${media.comment.community_id}/comment/${media.comment.id}`
      const isLink = !!media.comment.community_id

      const content = (
        <>
          <UserText
            comment={media.comment.text}
            commentTime={media.comment.created_at}
            headshot={media.comment.user?.headshot}
            name={media.comment.user?.name}
            uid={media.comment.user?.uid}
          />
          {media.comment.media && <MultiMedia media={media.comment.media[0]} />}
        </>
      )
      if (isLink) {
        return (
          <Link
            href={link}
            className={media.comment.media ? '' : 'w-fit'}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {content}
          </Link>
        )
      }

      return <div className={media.comment.media ? '' : 'w-fit'}>{content}</div>
    }
  }

  return <MediaShare media={media} />
}

export default MultiMedia
